import "./styles.css";
import styled from "styled-components";
import Highcharts from "highcharts";
import HighchartMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";

const MOBILE_SIZE = 768;

const Container = styled.div`
  @media only screen and (min-width: 1280px) {
    width: 635px;
  }

  .highcharts-point {
    height: 12px;
    width: 12px;
    y: 9;
  }
  .highcharts-legend-item {
    .item-group {
      display: flex;
      justify-content: space-between;
      padding-bottom: 14px;
      .item-name {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.lastItem {
        padding-bottom: 10px;
      }
      &.score {
        border-top: 1px solid #cbcbcb;
        padding-top: 12px;
        padding-bottom: 0;
        .item-name {
          font-size: 20px;
        }
      }
    }
  }
  .highcharts-data-label {
    .score-group {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }
    .score-name {
      font-size: 18px;
      line-height: 1rem;
    }
    .score-value {
      font-size: 40px;
      font-weight: bold;
    }
  }
`;

HighchartMore(Highcharts);

(function (H) {
  H.wrap(H.Legend.prototype, "layoutItem", function (proceed, item) {
    var options = this.options,
      padding = this.padding,
      horizontal = options.layout === "horizontal",
      itemHeight = item.itemHeight,
      itemMarginBottom = options.itemMarginBottom || 0,
      itemMarginTop = this.itemMarginTop,
      itemDistance = horizontal ? H.pick(options.itemDistance, 20) : 0,
      maxLegendWidth = this.maxLegendWidth,
      itemWidth =
        options.alignColumns && this.totalItemWidth > maxLegendWidth
          ? this.maxItemWidth
          : item.itemWidth;

    // If the item exceeds the width, start a new line
    if (
      horizontal &&
      (this.itemX - padding + itemWidth > maxLegendWidth ||
        item.userOptions.newLine)
    ) {
      this.itemX = padding;
      this.itemY += itemMarginTop + this.lastLineHeight + itemMarginBottom;
      this.lastLineHeight = 0; // reset for next line (#915, #3976)
    }

    // Set the edge positions
    this.lastItemY = itemMarginTop + this.itemY + itemMarginBottom;
    this.lastLineHeight = Math.max(
      // #915
      itemHeight,
      this.lastLineHeight
    );

    // cache the position of the newly generated or reordered items
    item._legendItemPos = [this.itemX, this.itemY];

    // advance
    if (horizontal) {
      this.itemX += itemWidth;
    } else {
      this.itemY += itemMarginTop + itemHeight + itemMarginBottom;
      this.lastLineHeight = itemHeight;
    }

    // the width of the widest item
    this.offsetWidth =
      this.widthOption ||
      Math.max(
        (horizontal
          ? this.itemX -
            padding -
            (item.checkbox
              ? // decrease by itemDistance only when no checkbox #4853
                0
              : itemDistance)
          : itemWidth) + padding,
        this.offsetWidth
      );
  });
})(Highcharts);

const config = {
  ideal: {
    text: "理想地區",
    color: "#edbd5d"
  },
  transportation: {
    text: "交通",
    color: "#60aa5f"
  },
  budget: {
    text: "預算",
    color: "#e96c3e"
  },
  net_area: {
    text: "實用面積",
    color: "#84cbf7"
  },
  bedroom: {
    text: "間隔",
    color: "#fee4b6"
  },
  school_net: {
    text: "校網",
    color: "#61afbf"
  },
  facilities: {
    text: "屋苑設施",
    color: "#416dd3"
  },
  bldg_age: {
    text: "樓齡",
    color: "#878177"
  },
  district_facilities: {
    text: "地區設施",
    color: "#da80bb"
  },
  transaction: {
    text: "成交流動性",
    color: "#ac4f48"
  },
  score: {
    text: "樓盤評分",
    color: "#000000"
  }
};

const order = [
  "ideal",
  "transportation",
  "budget",
  "net_area",
  "bedroom",
  "school_net",
  "facilities",
  "bldg_age",
  "district_facilities",
  "transaction"
];

const data = {
  ideal: 7,
  transportation: 8,
  budget: 9,
  net_area: 6,
  bedroom: 5,
  school_net: 4,
  facilities: 8,
  bldg_age: 2,
  district_facilities: 7,
  transaction: 5,
  score: 61
};

function adjustLegend() {
  const legend = this.legend;
  let maxLegendWidth = legend.maxLegendWidth;

  let normalLegendWidth = maxLegendWidth;
  if (window.innerWidth < MOBILE_SIZE) {
    normalLegendWidth = normalLegendWidth / 2;
  }
  legend.allItems.forEach((item, i, allItems) => {
    if (item?.userOptions?.id === "score") {
      item.legendItem.element.onmouseover = null;
      item.legendItem.element.style.left = 0;
      item.legendItem.element.children[0].style.width = `${
        maxLegendWidth - 20
      }px`;
      if (window.innerWidth < MOBILE_SIZE) {
        item.legendItem.onmouseover = null;
        item.legendItem.htmlCss({
          minWidth: `${maxLegendWidth}px`
        });
      }
      return;
    }
    item.legendItem.element.children[0].style.width = `${
      normalLegendWidth - 40
    }px`;
  });
}

export default function App() {
  const chartOption = {
    chart: {
      polar: true,
      type: "line",
      height: "600px",
      events: {
        load: adjustLegend,
        redraw: adjustLegend
      }
    },

    credits: {
      enabled: false
    },

    title: {
      text: undefined
    },

    pane: {
      size: "70%"
    },

    xAxis: {
      categories: order.map((key) => config?.[key]?.text),
      tickmarkPlacement: "on",
      lineWidth: 0,
      gridLineColor: "#F0F0F0",
      labels: {
        style: {
          fontSize: "16px",
          color: "#8D8D8D"
        }
      }
    },

    yAxis: {
      gridLineInterpolation: "polygon",
      lineWidth: 0,
      gridLineColor: "#F0F0F0",
      tickInterval: 2,
      labels: {
        enabled: false
      }
    },

    tooltip: {
      shared: true,
      pointFormat:
        '<span style="color:{series.color}"><b>{point.y:,.0f}</b><br/>'
    },

    legend: {
      width: "30%",
      padding: 20,
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      useHTML: true,
      backgroundColor: "#F0F0F0",
      itemStyle: {
        fontSize: "16px",
        lineHeight: "25px",
        cursor: "default"
      },
      navigation: {
        enabled: false
      },
      labelFormatter: function (item) {
        const name = this.name;
        const id = this.userOptions?.id;
        const lastItem = this.index === this.yData.length;
        const count =
          id === "score" ? data?.score : this?.options?.data?.[this.index - 1];
        return `
        <div class="item-group ${lastItem ? "lastItem" : ""} ${
          id === "score" ? "score" : ""
        }">
          <span class="item-name">${name}</span>
          <span class="item-value">${count}</span>
        </div>
        `;
      }
    },

    series: [
      {
        id: "area",
        type: "area",
        color: "#feefab",
        lineColor: "#eedf80",
        fillOpacity: 0.8,
        data: order.map((key) => data?.[key]),
        showInLegend: false,
        enableMouseTracking: false,
        marker: {
          enabled: false
        },
        pointPlacement: "on"
      },
      ...order.map((key, index) => ({
        type: "area",
        name: config?.[key]?.text,
        color: config?.[key]?.color,
        data: new Array(order.length)
          .fill(null)
          .fill(data?.[key], index, index + 1),
        marker: {
          symbol: "circle"
        },
        point: {
          events: {
            mouseOver: function (e) {
              const legend = e.target?.series?.legendItem?.element;
              if (legend) {
                legend.onmouseover();
              }
            },
            mouseOut: function (e) {
              const legend = e.target?.series?.legendItem?.element;
              if (legend) {
                legend.onmouseout();
              } // $(e.currentTarget.legendItem.element).trigger("mouseout");
            }
          }
        },
        // linkedTo: "area",
        // showInLegend: true,
        // enableMouseTracking: false,
        pointPlacement: "on",
        events: {
          legendItemClick: function (e) {
            e.preventDefault();
          }
        }
      })),
      {
        name: config?.score?.text,
        id: "score",
        type: "area",
        data: [0],
        color: "transparent",
        marker: {
          enabled: false
        },
        dataLabels: {
          useHTML: true,
          enabled: true,
          verticalAlign: "middle",
          formatter: function () {
            const name = this.series.name;
            const score = data?.score;
            return `
            <div class="score-group">
              <span class="score-name">${name}</span>
              <span class="score-value">${score}</span>
            </div>
            `;
          }
        },
        enableMouseTracking: false,
        pointPlacement: "on",
        events: {
          legendItemClick: function (e) {
            e.preventDefault();
          }
        },
        newLine: true
      }
    ],
    responsive: {
      rules: [
        {
          condition: {
            callback: () => window.innerWidth < MOBILE_SIZE
          },
          chartOptions: {
            // legend: {
            //   width: "100%",
            //   padding: "bottom",
            //   align: "center",
            //   verticalAlign: "middle",
            //   layout: "vertical",
            //   useHTML: true,
            //   backgroundColor: "#F0F0F0"
            // },
            legend: {
              width: 320,
              itemWidth: 160,
              align: "center",
              verticalAlign: "bottom",
              layout: "horizontal"
            }
          }
        }
      ]
    }
  };

  return (
    <div className="App">
      <Container>
        <HighchartsReact
          // constructorType="mapChart"
          highcharts={Highcharts}
          options={chartOption}
          callback={(chart) => {
            // setChartInstance(chart);
          }}
        />
      </Container>
    </div>
  );
}
